// Here you can add other styles

.footer-modal {
  margin: 1em 0;
}

.cardheader-with-btn {
  display: flex;
  justify-content: space-between;
  z-index: 10 !important;
}

.search-field {
  width: 40%;
}

.filter-field {
  width: 20%;
}

.required::after {
  content: '*';
  color: red;
}

.form-group-inline {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.piece-label {
  display: flex;
  align-items: center;
}

.spinner-datatable {
  margin-top: 1em;
}

.action-btn {
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.bold {
  font-weight: bold;
}

.add-btn {
  margin-bottom: 1em;
}

.add-btn-container {
  display: flex;
  align-items: baseline;
}

.info-note-btn {
  margin-left: 1em;
  font-size: small;
  font-weight: bold;
  color: #979696;
}

.card {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.medium-size {
  width: 50%;
}

.btn-action-col {
  margin: 0 auto;
  width: 'fit-content';
  text-wrap: 'normal';
}

.cgu {
  display: flex;
}

.cgu-label {
  margin-left: 1em;
}

.select-filter {
  width: 200px;
}

.pdf-attachment-container {
  display: flex;
}

.img-pdf {
  width: 40px;
  margin-right: 1em;
}

.file-name-pdf {
  word-wrap: break-word;
  width: 20vw;
}

.avatar-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-img-sm {
  width: 28px!important;
  height: 28px!important;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.detail-matrix-th-td {
  width: fit-content;
}

table.table-matrix {
  border-collapse: initial !important;
  width: 100%;
}

table.table-matrix th,
table.table-matrix td {
  border: 1px solid rgba(153, 171, 179, 0.637);
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

table.table-matrix th {
  background-color: #f2f2f2;
  font-weight: bold;
}

table.table-matrix td {
  width: 100px;
  height: 100px;
}

table.table-matrix td.table-matrix-title {
  border: 0px;
}

.vertical-text {
  writing-mode: vertical-rl; /* Écriture verticale de droite à gauche */
  transform: rotate(180deg); /* Rotation du texte à 180 degrés */
  white-space: nowrap;
}

.detailVErsionExigence-end-btn-container {
  width: 100%;
  text-align: end;
  padding: 1em;
}

.common-btn-action {
  margin-left: 5px;
  margin-right: 5px;
}

.common-spinner-detail {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.addEddidDocument_btn-remove {
  margin: -5px 0px 2px;
  float: right;
}

.link_btn-remove {
  margin: -5px 0px 2px;
  float: right;
}

.incident_add_location-btn {
}

.incident_add_location-group {
  display: flex;
}

.incident_add_location-input {
  flex: 1;
}

/* affichage label value*/
.info-container {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.info-label {
  margin-right: 10px;
  color: #333;
}

.info-value {
  color: #555;
}

/* end affichage label value*/

.list-group-action {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  /*text-decoration: underline;*/
  font-weight: bold;
}

/* table fiche processus*/

.back-btn {
  margin: 1em 24px;
}

.title-bpmn {
  background: #223367;
  color: white;
  margin: 1em;
  text-align: center;
  padding: 9px;
  font-size: large;
  text-transform: uppercase;
  font-weight: bolder;
}

.zoom-btn {
  width: 30px;
  background: #e7e6e6;
  height: 30px;
}

.look-bpmn-btn {
  background: var(--ant-primary-color);
  width: fit-content;
  padding: 1em;
  border-radius: 20px;
  position: fixed;
  bottom: 35px;
  right: 37px;
  color: #fff;
  cursor: pointer;
  z-index: 2;
}

.gw-table {
  height: 100%;
}

.gw-table td > tr {
  display: flex;
  flex-wrap: wrap;
}

/* .gw-table tr{
        height: 40px;
    } */
.gw-table td {
  padding-left: 10px;
  /* padding-top: 5px; */
  padding-bottom: 5px;
  padding-right: 10px;
  /* vertical-align: initial; */
  height: 100%;
  margin-top: 5px;
  position: relative !important;
}

.gw-table td.valueCell {
  height: 100px !important;
  vertical-align: top !important;
  background-color: #fff !important;
}

.gw-table.full {
  width: 100%;
}

.gw-table.bordered {
  border: 1px solid #e5e7eb;
}

.gw-table.bordered > tbody > tr {
  border: 1px solid #e5e7eb;
}

.gw-table.bordered > tbody > tr > td {
  border: 1px solid #e5e7eb;
}

.gw-table.bordered tr.no-border {
  border: 0px solid #e5e7eb !important;
}

.gw-table .border-t {
  border-top: 1px solid #e5e7eb;
}

.gw-table .border-b {
  border-bottom: 1px solid #e5e7eb;
}

.gw-table .border-l {
  border-left: 1px solid #e5e7eb;
}

.gw-table .border-r {
  border-right: 1px solid #e5e7eb;
}

.gw-table .txt-center {
  text-align: center;
}

.gw-table .txt-left {
  text-align: left;
}

.gw-table .txt-right {
  text-align: right;
}

.gw-table td.w20,
div.w20 {
  width: 20% !important;
}

.gw-table td.w25,
div.w25 {
  width: 25% !important;
}

.gw-table td.w50,
div.w50 {
  width: 50% !important;
}

.gw-table td.w75,
div.w75 {
  width: 75% !important;
}

.gw-table td.w80,
div.w80 {
  width: 80% !important;
}

.gw-table tr.h10p {
  height: 10px !important;
}

.gw-table tr.h20p {
  height: 20px !important;
}

.gw-table tr.h30p {
  height: 30px !important;
}

.gw-table tr.h50p {
  height: 50px !important;
}

.gw-table tr.h75p {
  height: 75px !important;
}

.gw-table tr.h100p {
  height: 100px !important;
}

.gw-table tr.h150p {
  height: 150px !important;
}

.gw-table td.contents {
  display: contents;
}

.gw-table .strategy-title {
  background: #5d92fd;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
}

.gw-table .title-full {
  background-color: #f9fafb;
  color: #444444;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.gw-table .title-outline {
  background-color: #fff;
  color: #000;
}

.gw-table .align-v-center {
  display: flex;
  align-items: center;
}

.header-fp-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 5px;
}

.fp-logo {
  margin-left: 10px !important;
  width: 50px;
  display: flex;
  align-items: center;
}

.fp-info {
  min-width: 200px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.fp-fullscreen {
  float: right;
  position: absolute;
  right: 100px;
}

.spaced-elt {
  display: flex;
  justify-content: space-between;
}

.h-inherit {
  height: inherit !important;
}

.subTableContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative !important;
  top: 0 !important;
  bottom: 0 !important;
}

.rowContainer {
  display: flex;
  min-height: 45px;
}

.rowContainer-bis {
  display: flex;
  min-height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100% !important;
}

.rowLabel {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 400;
}

.rowValue {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  background-color: #fff !important;
  font-size: 14px;
  font-weight: 400;
}
.rowValue.without-padding {
  padding: 0px 5px !important;
}

.mih45p {
  min-height: 45px !important;
}

.mih100p {
  min-height: 100px !important;
}

.mih150p {
  min-height: 150px !important;
}

.mih200p {
  min-height: 200px !important;
}

.mih225p {
  min-height: 225px !important;
}

.mah75p {
  max-height: 75px !important;
}

.mah150p {
  max-height: 150px !important;
}

.mah200p {
  max-height: 200px !important;
}

.flex1 {
  flex: 1 !important;
}

.mah40p {
  max-height: 40px !important;
}

.gw-table .h40p {
  height: 40px !important;
}

.pl10p {
  padding-left: 10px !important;
}

.pr10p {
  padding-right: 10px !important;
}

.ml10p {
  padding-left: 10px !important;
}

.no-padding {
  padding: 0% !important;
}

.texte-avec-sauts-de-ligne {
  white-space: pre-line;
}

.fp-info-label {
  margin-right: 10px;
}
/* end table fiche processus*/

/* bpmn */
.bpmn-editor-main-container {
  height: 90%;
  position: relative;
}

.bpmn-editor-btn-header-container{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  display: flex 

}

.bpmn-editorvue-bpmn-diagram-container {
  height: 100%;
  width: 100%;
}

.bpmn-editor-btn {
  z-index: 999;
  margin: 5px;
}

.bpmn-editor-btn-container {
  position: relative;
}
/* bpmn */

.date-evaluation-container {
  margin-top: 15px;
}

.react-select__menu {
  background-color: #fff !important;
  z-index: 10 !important;
}

.fs-14 {
  font-size: 14px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.list-with-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detail-person-profile-img {
  width: 150px!important;
  height: 150px!important;
  border-radius: 15px;
  object-fit: cover;
  box-shadow: 3px 3px 1px #ccc;
  -webkit-box-shadow: 3px 3px 1px #ccc;
  -moz-box-shadow: 3px 3px 1px #ccc;
  margin-top: 5px;
  margin-bottom: 5px;
}

.icon-title {
  margin-right: 7px;
}

.align-top-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.flex-item {
  flex: 1;
  min-width: 200px;
  max-width: calc(100% - 210px);
}

.gw-sidebar-menu-item-icon {
  max-width: 20px;
}

.banner-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  color: #000;
  text-align: center;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 10px;
}

.banner-item {
  padding: 5px;
  background-color: #ffc107; 
  width: 100%;
  margin: 1px;
  font-weight: 600 !important;
}

.banner-item p{
  margin: 0px !important;
}

.banner-item .alert{
  margin: 2px !important;
  margin-bottom: 2px !important;
}

// @keyframes blink {
//   0% { opacity: 1; }
//   50% { opacity: 0; }
//   100% { opacity: 1; }
// }

// .banner-item {
//   animation: blink 2s infinite;
// }

// @keyframes shake {
//   0% { transform: translateX(0); }
//   25% { transform: translateX(-5px); }
//   50% { transform: translateX(5px); }
//   75% { transform: translateX(-5px); }
//   100% { transform: translateX(0); }
// }

// .banner-item {
//   animation: shake 1s infinite;
// }
.img-icon-list {
  width: 20px;
  cursor: pointer;
}

.gw-capitalize{
  text-transform: capitalize;
}

.Organization_avatar_container > *:not(:first-child) {
  margin-left: -10px;
}

.Organization_node{
  width: unset!important;
}

.reactOrgChart .orgNodeChildGroup .node {
  margin: 0px 5px !important;
  background-color: #f2f2f2 !important;

}

.remaining-list {
  max-width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.remaining-list div:hover {
  background-color: #f8f9fa;
  border-radius: 5px;
  padding: 2px;
}

.Organization_node_title{
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/*.Organization_tree_container > *:not(:first-child)::before {
  content: '';  
  display: block; 
  width: 100%; 
  border-top: 1px solid #444444; 
  margin: 20px 0; 
}*/

.Organization_tree_container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Empêche le débordement */
}

.Organization_tree_container .react-transform-wrapper{
  width: 100% !important;
}

.zoom-controls {
  position: absolute;
  top: 0px;
  left: 5px;
  z-index: 10;
  display: flex;
  gap: 10px;
}

.zoom-controls button:hover {
  background-color: #0056b3;
}

.gw-sidebar-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 5px;
}

.gw-sidebar-without-submenu.gw-sidebar-collapsed .company-logo-img{
  max-width: 50px !important;
  max-height: 40px !important;
  object-fit: contain;
}

.gw-sidebar-expanded .company-logo-img {
  max-width: 100px !important;
  max-height: 80px !important;
  object-fit: contain;
}

.gw-sidebar-with-submenu.gw-sidebar-collapsed .company-logo-img{
  max-width: 40px !important;
  max-height: 30px !important;
  object-fit: contain;
}
