body {
  font-family: 'Inter', sans-serif;
  background: #fcfcfc;
  --cui-body-bg: #fcfcfc !important;
}

.bg-light {
  background-color: #fcfcfc !important;
}

.filter-field {
  width: 100% !important;
}

.gw-section-title {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 17px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.gw-link-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 2px;
  margin: 0;
  line-height: normal !important;
}

.gw-link-item-container a {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #5d92fd;
}

.list-group-action {
  margin-top: 0px !important;
}

/**********************
    HEADER - START
***********************/
.gw-header.header {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  --cui-header-bg: #5d92fd !important;
  background: #5d92fd !important;
  --cui-header-min-height: 60px !important;
  z-index: 1050 !important;
}

.gw-header.header svg.icon {
  color: white !important;
}

.gw-header.header .avatar-img {
  width: 32px !important;
  height: 32px !important;
}

html:not([dir='rtl']) .header-brand {
  margin-right: 1rem !important;
}

.gw-header .header-search-bar {
  width: 100% !important;
  height: 37px !important;
  border-radius: 30px !important;
}

.gw-header .header-search-bar .result-search {
  max-width: 1024px !important;
  width: 100%;
  //width: 50px ; /* Taille fixe en largeur */
  max-height: 500px; /* Hauteur maximale ajustable */
  overflow-y: auto; /* Barre de défilement si nécessaire */
  position: absolute; /* Position absolue pour maintenir la position sous l'input */
  z-index: 9999; /* S'assurer que le pop-up est au-dessus des autres éléments */
  
}

.gw-header .header-search-bar-mobile {
  width: 100% !important;
  height: 37px !important;
  border-radius: 30px !important;
}

.gw-header .header-search-bar .input-group-text {
  border-radius: 30px 0px 0px 30px !important;
  color: #fff;
  background: #ffffff40;
  border: none !important;
}

.gw-header .header-search-bar .header-search-bar-input {
  border-radius: 70px 0px 0px 70px !important;
  border-top-right-radius: unset!important;
  border-bottom-right-radius: unset!important;
  color: #fff;
  background: #ffffff40;
  border: none !important;
  padding-left: 20px;
  padding-right: 10px; /* Facultatif : Ajoute 10px d'espace à droite */
  padding-top: 5px; /* Facultatif : Espace au-dessus du texte */
  padding-bottom: 5px;
}
.header-search-bar .header-search-bar-reset-btn{
  border-radius:unset!important;
    color: #fff;
  background: #ffffff40;
  border: unset;
  border-left: 1px solid #fff;

}

.header-search-bar .header-search-bar-submit-btn{
  border-radius:unset!important;
  border-top-right-radius: 30px!important;
  border-bottom-right-radius: 30px!important;
    color: #fff;
  background: #ffffff40;
  border: unset;
  border-left: 1px solid #fff;
}

.header-search-bar .header-search-bar-submit-btn.disabled{
  border-radius:unset!important;
  border-top-right-radius: 30px!important;
  border-bottom-right-radius: 30px!important;
    color: #fff;
  background: #fafafa40;
  border: unset;
  border-left: 1px solid #fff;
}
.clear-btn {
  border: 1px solid #ccc; /* Bordure grise */
  border-radius: 5px; /* Coins arrondis */
  padding: 5px 10px; /* Ajoute de l'espace à l'intérieur du bouton */
  background-color: #f8f9fa; /* Arrière-plan léger pour le bouton */
  cursor: pointer; /* Change le curseur pour montrer que c'est cliquable */
  transition: background-color 0.3s ease, border-color 0.3s ease; /* Effet de transition */
  display: inline-block; /* Assure que le bouton ait une taille adaptée */
  
  /* Centrage horizontal */
  margin: 0 auto; /* Centre l'élément horizontalement */
}

.clear-btn-container {
  display: flex; /* Active le flexbox pour le centrage */
  justify-content: center; /* Centre le bouton horizontalement */
  align-items: center; /* Centre verticalement si nécessaire */
  width: 100%; /* S'assure que le conteneur prenne toute la largeur */
}

.clear-btn:hover {
  background-color: #e9ecef; /* Arrière-plan légèrement plus sombre au survol */
  border-color: #999; /* Couleur de bordure changeante au survol */
}




html:not([dir='rtl'])
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(
    .invalid-tooltip
  ):not(.invalid-feedback) {
  margin-left: 0px !important;
}

.gw-header .header-search-bar input.header-search-bar-input::placeholder {
  color: #fff !important;
}
.header-user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.header-menu-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.header-user-label {
  color: #ffffff;
  font-size: 14px;
}

.header-user-label.bold {
  font-weight: 'bold';
}

.gw-header-dropdown-user-menu {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}

.gw-header-dropdown-user-menu.gw-header-dropdown-user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px !important;
  color: #444444;
}

/*.gw-header .header-nav .dropdown-menu {
  width: 100% !important;
}*/

.gw-header .header-nav .dropdown-menu.result-search{
  max-width: 43%!important;
  width: fit-content!important;
}

.result-search-container {
  width: 400px !important; /* Largeur fixe pour le pop-up */
  position: absolute; /* Position absolue pour rester en dessous du champ de recherche */
  z-index: 9999; /* Pour que le pop-up reste au-dessus des autres éléments */
}

.result-search-menu {
  width: 100% !important; /* S'assure que le menu utilise la largeur du conteneur parent */
  max-height: 500px; /* Hauteur maximale du contenu */
  overflow-y: auto; /* Active un défilement si le contenu dépasse la hauteur maximale */
  border: 1px solid #ccc; /* Bordure pour mieux voir les dimensions */
  background-color: white; /* Couleur de fond pour différencier du reste de la page */
}

.result-search-content {
  display: flex; /* Utilise Flexbox pour aligner les résultats */
  flex-direction: column; /* Aligne les éléments verticalement */
}

.result-search-row {
  display: flex; /* Aligne l'icône et le texte sur la même ligne */
  align-items: center; /* Centre verticalement l'icône et le texte */
  padding: 5px 0; /* Espacement vertical */
}

.result-icon {
  display: inline-flex; /* Assure que l'icône reste sur la même ligne que le texte */
  justify-content: center;
  align-items: center;
  margin-right: 8px; /* Espace entre l'icône et le texte */
  font-size: 1.2em; /* Ajuste la taille de l'icône en fonction du texte */
  line-height: 1; /* Supprime tout espace supplémentaire autour de l'icône */
  vertical-align: middle; /* Aligne verticalement l'icône avec le texte */
}

.result-search-row-text {
  flex-grow: 1; /* Permet au texte de s'étendre */
    white-space: normal; /* Permet au texte de passer à la ligne */
    overflow-wrap: break-word; /* Permet de couper les mots trop longs */
}



.gw-header-dropdown-user-info .ghdu-name {
  font-weight: 700;
}

.gw-header-dropdown-user-info .ghdu-email {
  font-weight: 400;
  color: #929292;
}

.gw-header-dropdown-user-menu.gw-header-dropdown-user-signout {
  font-weight: 700;
}

/**********************
    HEADER - END
***********************/

/**********************
    SIDEBAR - START
***********************/

.sidebar.sidebar-fixed {
  position: fixed;
  top: 60px !important;
  bottom: 0;
  z-index: 1030;
}

.sidebar.gw-sidebar {
  --cui-sidebar-width: 22rem;
  --cui-sidebar-bg: #ffffff;
  --cui-sidebar-color: #283151;
}

.sidebar-narrow-unfoldable.gw-sidebar:hover {
  --cui-sidebar-width: 15rem !important;
  --cui-sidebar-occupy-start: 15rem !important;
}

.gw-sidebar.gw-sidebar-collapsed {
  --cui-sidebar-width: 18rem !important;
  --cui-sidebar-occupy-start: 18rem !important;
}

.gw-sidebar.gw-sidebar-collapsed.gw-sidebar-without-submenu {
  --cui-sidebar-width: 4rem !important;
  --cui-sidebar-occupy-start: 4rem !important;
}

.gw-sidebar.gw-sidebar-expanded {
  --cui-sidebar-width: 22rem !important;
  --cui-sidebar-occupy-start: 22rem !important;
}

.gw-sidebar.gw-sidebar-expanded.gw-sidebar-without-submenu {
  --cui-sidebar-width: 10rem !important;
  --cui-sidebar-occupy-start: 10rem !important;
}

.gw-sidebar.sidebar-fixed.sidebar-narrow-unfoldable:not(:hover) {
  width: 15rem !important;
  --cui-sidebar-width: 15rem !important;
  --cui-sidebar-occupy-start: 15rem !important;
}

.gw-sidebar.sidebar-fixed.sidebar-narrow-unfoldable.gw-sidebar-without-submenu:not(:hover) {
  width: 4rem !important;
  --cui-sidebar-width: 4rem !important;
  --cui-sidebar-occupy-start: 4rem !important;
}

.sidebar-narrow-unfoldable.gw-sidebar.gw-sidebar-without-submenu:hover {
  --cui-sidebar-width: 4rem !important;
  --cui-sidebar-occupy-start: 4rem !important;
}

.sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 18rem !important;
}

.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 22rem;
}

.sidebar.sidebar-fixed.sidebar-narrow-unfoldable.gw-sidebar.gw-sidebar-collapsed.gw-sidebar-with-submenu.show:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 15rem !important;
}

@media (min-width: 768px) {
  .gw-sidebar-expanded.gw-sidebar-without-submenu.sidebar:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 9.5rem !important;
  }
}
.gw-sidebar-collapsed.gw-sidebar-without-submenu.sidebar:not(.sidebar-end) ~ * {
  --cui-sidebar-occupy-start: 3.5rem !important;
}

.gw-sidebar-unfoldable {
  position: absolute !important;
  right: -10px !important;
  bottom: 50% !important;
  cursor: pointer !important;
}

.gw-sidebar-container {
  display: flex;
}

.gw-sidebar-main-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.gw-sidebar-menu-item-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.gw-sidebar-menu {
  //width: 50%;
  height: 100%;
  border-right: solid 2px #d8d8d840;
  padding-top: 10px;
  padding-bottom: 10px;
}

.gw-sidebar-submenu {
  //width: 50%;
}

.gw-sidebar-without-submenu .gw-sidebar-menu {
  width: 80% !important;
}

.gw-sidebar-without-submenu .gw-sidebar-submenu {
  width: 0% !important;
}

.gw-sidebar-with-submenu.gw-sidebar-expanded .gw-sidebar-menu {
  width: 45% !important;
}

.gw-sidebar-with-submenu.gw-sidebar-expanded .gw-sidebar-submenu {
  width: 55% !important;
}

.gw-sidebar-with-submenu.gw-sidebar-collapsed  .gw-sidebar-menu {
  width: 20% !important;
}

.gw-sidebar-with-submenu.gw-sidebar-collapsed  .gw-sidebar-submenu {
  width: 80% !important;
}

.gw-sidebar-without-submenu .gw-sidebar-menu {
  border-right: none !important;
}

.gw-sidebar-menu {
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}

.gw-sidebar-submenu-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 5px !important;
  padding-right: 10px !important;
}

.gw-sidebar-container {
  display: flex;
  height: 100%;
  padding: 10px 5px 10px 5px;
}

.gw-sidebar-menu-item-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 7px;
  padding-left: 8px;
  width: 100% !important;
}

a.gw-sidebar-menu-item-container {
  color: #283151;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

a.gw-sidebar-menu-item-container.selected {
  color: #5d92fd !important;
  background-color: #f0f2ff !important;
  border-radius: 100px !important;
}

.gw-sidebar-menu-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 15px !important;
  padding-right: 5px !important;
}

.gw-sidebar-menu-item-label {
  margin-left: 8px !important;
  word-break: break-word;
}

.gw-sidebar-submenu-item-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.gw-sidebar-submenu-item-container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  width: 100% !important;
}

a.gw-sidebar-submenu-item-container {
  color: #283151;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

a.gw-sidebar-submenu-item-container.selected {
  color: #5d92fd !important;
  background-color: #f0f2ff !important;
  border-radius: 100px !important;
}

.gw-sidebar-submenu-item-label {
  margin-left: 10px !important;
  word-break: break-word;
}

.gw-sidebar-submenu-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gw-sidebar-submenu-nav-sub {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 15px !important;
  padding-right: 0px !important;
}

/**********************
    SIDEBAR - END
***********************/

/**********************
    CONTAINER - START
***********************/

.card-body{
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.wrapper {
  position: relative;
  top: 60px !important;
  padding-top: 25px !important;
  min-height: 93vh;
}

.gw-appcontent {
  padding-top: 4px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

@media (min-width: 768px) {
  .gw-appcontent {
    padding-top: 30px !important;
  }
}

@media (max-width: 767.98px) {
  .card {
    --cui-card-spacer-x: 0rem;
    --cui-card-spacer-y: 0rem;
  }

  .card-header {
    padding: var(--cui-card-cap-padding-y) 0;
    padding-bottom: 0;
    margin-top: 0px;
  }

  .cardheader-with-btn {
    flex-direction: column;

    div:first-of-type {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      flex-wrap: wrap;

      .common-btn-action:nth-child(3) {
        width: 100%;
        margin-top: 4px;
      }
    }
  }

  .gw-info-datatable {
    display: flex;
    flex-direction: column;

    .gw-info-datatable-content.has-filter {
      justify-content: space-between;
    }

    .gw-info-datatable-content {
      width: 100%;
      flex-wrap: wrap;

      .common-btn-action,
      .btn-group {
        margin-top: 8px;
      }

      .input-group.search-field {
        width: 100% !important;
        margin-bottom: 4px;
      }
    }
  }

  .gw-appcontent {
    .gw-bordered-card:first-of-type {
      margin-top: 30px !important;
    }

    .gw-bordered-card {
      padding: 8px 8px !important;
    }
  }

  .common-btn-action {
    margin-left: 0px;
    margin-right: 0px;
  }

  .footer-modal {
    width: 100%;
  }
}

/**********************
    CONTAINER - END
***********************/

/**********************
    BREADCRUMB - START
***********************/

.gw-breadcrumb {
  position: fixed;
  top: 60px !important;
  background-color: #F8F9FA !important;
  --cui-breadcrumb-bg: #F8F9FA !important;
  width: 100% !important;
  z-index: 150 !important;
  padding-top: 10px !important;
  padding-bottom: 10px  !important;
  padding-left: 2px  !important;
  margin-left: 0px !important;
}

.gw-breadcrumb-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #666e82 !important;
}

.gw-breadcrumb-item-label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px !important;
  cursor: pointer !important;
  color: #666e82 !important;
  margin-top: 4px;
}

.gw-breadcrumb-item-content {
  display: flex;
  align-items: center !important;
}

.gw-breadcrumb-item-content a {
  text-decoration: none;
  cursor: pointer !important;
}

.gw-breadcrumb-item-content.active .gw-breadcrumb-item {
  color: #5d92fd !important;
}

.gw-breadcrumb-item-content.active .gw-breadcrumb-item-label {
  color: #5d92fd !important;
}

/**********************
    BREADCRUMB - END
***********************/

/**********************
    CCARD - START
***********************/
.card {
  background-color: transparent !important;
  border: none !important;
}

.card-header {
  background-color: transparent !important;
  border: none !important;
}

.card-header .card-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.gw-bordered-card {
  padding: 1.25px !important;
  border-radius: 7px !important;
  border: 1px solid #0000152d !important;
  background-color: #ffffff !important;
}

.gw-bordered-card .gw-card-header {
  padding: 20px 15px !important;
}

/**********************
    CCARD - END
***********************/

/**********************
    SEARCHFIELD - START
***********************/
.search-field {
  width: 215px !important;
  height: 37px !important;
  border-radius: 30px !important;
  color: #666e82;
}

.search-field .input-group-text.search-btn-prepend {
  border-radius: 30px 0px 0px 30px !important;
  color: #666e82;
  background: #d8d8d840;
  border: none !important;
  cursor: pointer;
  font-size: 14px !important;
}

.search-field .input-group-text.search-btn-append {
  border-radius: 0px 30px 30px 0px !important;
  color: #666e82;
  background: #d8d8d840;
  border: none !important;
  cursor: pointer;
}

.search-field .search-field-input {
  border-radius: 0px 30px 30px 0px !important;
  color: #666e82;
  background: #d8d8d840;
  border: none !important;
}

.search-field .search-field-input.hasSearched {
  border-radius: 0px 0px 0px 0px !important;
  color: #666e82;
  background: #d8d8d840 !important;
  border: none !important;
}

/**********************
    SEARCHFIELD - END
***********************/

/**********************
    DATATABLE - START
***********************/

.gw-react-datatable .rdt_TableHeadRow {
  background: #f9fafb !important;
  text-transform: uppercase !important;
  color: #444444 !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px !important;
  letter-spacing: 0em;
  min-height: 40px !important;
}

.gw-react-datatable .rdt_TableCol {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 10x !important;
}

.gw-react-datatable .rdt_TableCell {
  background: #ffffff !important;
}

.gw-react-datatable .rdt_TableCell {
  color: #444444 !important;
  min-height: 40px !important;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}

.rdt_TableCol input[type='checkbox'] {
  // background-color: #ff0000 !important;
}

.rdt_TableCell input[type='checkbox'] {
  // background-color: #ff0000 !important;
}

.gw-info-datatable {
  display: flex;
  align-items: flex-end !important;
  justify-content: space-between !important;
  margin-bottom: 20px !important;
}

.gw-info-datatable-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.gw-icon-in-table {
  padding: 0px 5px 0px 5px !important;
  cursor: pointer !important;
}

.gw-icon-in-table.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.rdt_Table .alert {
  margin: 0px !important;
  background-color: transparent !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.gw-table-filter {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #fff !important;
  border: 1px solid #eaeaea !important;
}

.gw-card-info-container .rdt_TableBody {
  height: 250px !important;
  overflow: auto !important;
}

.gw-card-info-container .rdt_TableCell .dashboard-value-title {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.gw-card-info-container .dashboard-value-date {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.8;
}

.gw-react-datatable.dashboard {
  max-height: 300px;
  overflow-y: auto;
}

/**********************
    DATATABLE - END
***********************/

/**********************
    BUTTON - START
***********************/

.btn {
  padding: 5px 11px !important;
  border-radius: 100px !important;
  gap: 8px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  // height: 37px !important;
}

.btn-group.toogleView .btn {
  border-radius: 2px !important;
}

.btn.btn-primary {
  -cui-btn-color: #ffffff;
  --cui-btn-bg: #5d92fd;
  --cui-btn-border-color: #5d92fd;
  --cui-btn-disabled-border-color: #5d92fd;
  background: #5d92fd;
}

.text-primary-h1 {
  color: #5d92fd !important;
  font-weight: 700;
}

.btn-link {
  color: #5d92fd;
  text-decoration: none;
}

.btn.btn-danger {
  -cui-btn-color: #ffffff;
  --cui-btn-bg: #ff0000;
  --cui-btn-border-color: #ff0000;
  background: #ff0000;
}

.btn-label {
  margin-left: 5px !important;
}

.btn-outline-primary {
  -cui-btn-color: #5d92fd;
  --cui-btn-border-color: #5d92fd;
  --cui-btn-hover-color: rgba(255, 255, 255, 0.87);
  --cui-btn-hover-bg: #5d92fd;
  --cui-btn-hover-border-color: #5d92fd;
  --cui-btn-active-color: rgba(255, 255, 255, 0.87);
  --cui-btn-active-bg: #5d92fd;
  --cui-btn-active-border-color: #5d92fd;
}

.common-btn-action.filter {
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 2px !important;
}

/**********************
    BUTTON - END
***********************/

/**********************
    TABPANE - START
***********************/
.gw-tabpane-container {
  padding-top: 2rem !important;
}

/**********************
    TABPANE - END
***********************/

/**********************
    NAV - START
***********************/
.gw-nav {
  margin-top: 10px !important;
}

@media (max-width: 767px) {
  .gw-nav {
    margin-top: 24px !important;
  }
}

.gw-nav .gw-navitem {
  --cui-nav-pills-border-radius: 0.375rem;
  --cui-nav-pills-link-active-color: rgba(255, 255, 255, 0.87);
  --cui-nav-pills-link-active-bg: #321fdb;
}

.gw-navitem-link {
  color: #5d92fd !important;
  background-color: #f0f2ff !important;
  border-radius: 100px !important;
  cursor: pointer !important;
}

.gw-navitem-link.tab {
  border-radius: 0px !important;
  color: #6b7280 !important;
  background-color: transparent !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.gw-navitem-link.active {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #5d92fd !important;
}

.gw-navitem-link.tab.active {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #5d92fd !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

/**********************
    NAV - END
***********************/

/**********************
    DROPDOWN - START
***********************/

.gw-dropdown-menu-filter {
  width: 300px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.gw-dropdown-menu-filter .accordion-button {
  padding: 5px 15px !important;
}

/**********************
    DROPDOWN - END
***********************/

/**********************
    BADGE - START
***********************/
.badge {
  padding: 2px, 10px, 2px, 10px !important;
  border-radius: 50px !important;
  gap: 10px !important;
  --cui-badge-font-size: 1em !important;
  text-transform: capitalize !important;
}

.badge.bg-primary {
  background: #5d92fd33 !important;
  color: #5d92fd !important;
}

.badge.bg-success {
  background: #86e51e33 !important;
  color: #6bcd00 !important;
}

.badge.bg-danger {
  background: #ff000033 !important;
  color: #ff0000 !important;
}

.badge.bg-warning {
  background: #f9b11533 !important;
  color: #f9b115 !important;
}

.badge.bg-info {
  background: #3399ff33 !important;
  color: #3399ff !important;
}

.badge.bg-light {
  background: #ebedef33 !important;
  color: #ebedef !important;
}

.badge.bg-dark {
  background: #4f5d7333 !important;
  color: #4f5d73 !important;
}

/**********************
    BADGE - END
***********************/

/**********************
    LABEL - START
***********************/
.gw-label-infos {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #666e82 !important;
}

.gw-value-infos {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #283151 !important;
}

.gw-value-infos .url-item a {
  text-decoration: none;
  color: #283151 !important;
}

.gw-label-value-container {
  padding: 5px 15px !important;
}

/**********************
    LABEL - END
***********************/

/**********************
    SELECT - END
***********************/
.gw-select-filter {
  padding: 0px !important;
  border-radius: 100px !important;
  border: 1px solid #eaeaea !important;
  font-weight: 600 !important;
}

.gw-select-filter option {
  color: #283151 !important;
}

/**********************
    SELECT - END
***********************/

/**************************
    FILTER-SEARCH - START
**************************/

.search-field.filter-search {
  width: 140px !important;
  height: 30px !important;
  border-radius: 30px !important;
  color: #283151 !important;
}

.search-field.filter-search.filter-date {
  width: 200px !important;
}

.search-field.filter-search .input-group-text.search-btn-prepend {
  border-radius: 30px 0px 0px 30px !important;
  color: #283151 !important;
  background: #fff !important;
  border: 1px solid #eaeaea !important;
  cursor: pointer !important;
  padding: 1px 5px !important;
  border-right: none !important;
  font-size: 14px !important;
}

.search-field.filter-search .input-group-text.search-btn-append {
  border-radius: 0px 30px 30px 0px !important;
  color: #283151 !important;
  background: #fff !important;
  border: 1px solid #eaeaea !important;
  cursor: pointer !important;
  border-left: none !important;
}

.search-field.filter-search .search-field-input {
  border-radius: 0px 30px 30px 0px !important;
  color: #283151 !important;
  background: #fff !important;
  border: 1px solid #eaeaea !important;
  border-left: none !important;
}

.search-field.filter-search .search-field-input.hasSearched {
  border-radius: 0px 0px 0px 0px !important;
  color: #283151 !important;
  background: #fff !important;
  border: 1px solid #eaeaea !important;
  border-left: none !important;
  border-right: none !important;
}

/**************************
    FILTER-SEARCH - END
**************************/

/**************************
    SPINNER - START
**************************/

.gw-general-spinner {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 100vh !important;
}

/**************************
    SPINNER - END
**************************/

/**************************
    IMG - START
**************************/

img {
  vertical-align: sub;
}

/**************************
    IMG - END
**************************/

@media (max-width: 767.98px) {
  html:not([dir='rtl']) .sidebar:not(.sidebar-end):not(.show) {
    margin-left: calc(-1 * var(--cui-sidebar-width));
  }

  .sidebar-narrow-unfoldable:not(.sidebar-end) ~ * {
    --cui-sidebar-occupy-start: 0rem !important;
  }
}

/*** form styles ***/
$greyClear: #f9fafb;
$grey: #e5e7eb;
$grey4: #929292;
$greyDark: #444444;
$blue: #5d92fd;

.gw-form {
  .accordion-collapse {
    margin-top: -16px;
  }

  .form-group {
    border: 2px solid $grey;
    background-color: $greyClear;
    border-radius: 8px;
    padding: 6px 16px 8px 16px;
    margin: 12px 0;

    label {
      color: $grey4;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 2px;
    }

    .form-control {
      padding: 0;
      border: none;
      color: $greyDark;
      background-color: $greyClear;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      border-radius: 0px;
    }

    .form-control:focus {
      box-shadow: none;
    }

    .tox-tinymce {
      background-color: $greyClear;
      border: none;
      border-radius: 0px;
      font-family: 'Inter';
      z-index: 0 !important;
    }

    // .tox .tox-menubar {
    //     visibility: hidden;
    // }

    .tox:not(.tox-tinymce-inline) .tox-editor-header,
    .tox .tox-toolbar-overlord,
    .tox .tox-toolbar,
    .tox .tox-toolbar__overflow,
    .tox .tox-toolbar__primary,
    .tox .tox-menubar,
    .tox .tox-edit-area__iframe,
    .tox .tox-statusbar {
      background-color: $greyClear;
      box-shadow: none;
    }

    .tox:not(.tox-tinymce-inline) .tox-editor-header {
      border-bottom: 1px solid $grey;
    }

    .react-select__control,
    .react-select__control--is-focused,
    .react-select__value-container,
    .react-select__placeholder,
    [class$='-container'],
    [class$='-control'],
    [class$='-ValueContainer'],
    [class$='-placeholder'],
    .form-select {
      border: none;
      background-color: $greyClear;
      padding: 0px;
      color: $greyDark !important;
      min-height: auto;
      box-shadow: none;
      margin: -2px 0px 0px 0px !important;
    }

    .react-select__indicator-separator,
    [class$='-indicatorSeparator'] {
      display: none;
    }

    .react-select__indicator,
    [class$='-indicatorContainer'] {
      color: $greyDark;
    }
  }

  .accordion {
    margin-top: 12px;
    --cui-accordion-border-radius: 0px;
    --cui-accordion-border-color: transparent;

    .accordion-item {
      border-top: 1px solid $grey;
      --cui-accordion-btn-padding-y: 14px;
      --cui-accordion-btn-padding-x: 8px;

      .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }

      .accordion-button {
        font-weight: 600;
        font-size: 15px;
        font-family: 'Inter';
        color: $greyDark;
      }

      .accordion-button:not(.collapsed) {
        font-weight: 600;
        font-size: 15px;
        font-family: 'Inter';
        color: $greyDark;
        background-color: $greyClear;
      }

      .accordion-button:focus {
        box-shadow: none;
      }
    }

    .list-group-item {
      border: none;
      border-top: 1px solid $grey;
    }
  }

  .text-danger {
    font-size: 10px;
  }

  .addEddidDocument_btn-remove {
    position: absolute;
    right: 0px;
    top: 15px;
    background-color: $greyClear;
  }

  input[type='file'] {
    border-radius: 8px !important;
    background-color: $greyClear !important;
    height: 36px !important;
    padding-top: 10px !important;
    position: relative;
    border: 1px solid $grey !important;
  }

  input[type='file']::before {
    content: 'Choisir un fichier';
    background-color: $blue;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #ffff;
    position: absolute;
    padding: 9px 7px;
    top: 0px;
  }

  .form-group a {
    font-size: 14px;
    margin-left: 8px;
  }
}

.gw-form2 {
  .accordion {
    margin-top: 12px;
    --cui-accordion-border-radius: 8px;
    --cui-accordion-border-color: #e5e7eb;

    .accordion-body {
      padding-top: 8px;
    }

    .accordion-item {
      border-top: 1px solid $grey;
      --cui-accordion-btn-padding-y: 14px;
      --cui-accordion-btn-padding-x: 8px;

      .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }

      .accordion-button {
        font-weight: 600;
        font-size: 15px;
        font-family: 'Inter';
        color: $greyDark;
      }

      .accordion-button:not(.collapsed) {
        font-weight: 600;
        font-size: 15px;
        font-family: 'Inter';
        color: $greyDark;
        background-color: #f6f9ff;
      }

      .accordion-button:focus {
        box-shadow: none;
      }
    }

    .list-group-item {
      border: 1px solid $grey;
    }
  }
}

.modal-title {
  font-size: 18px;
  font-weight: 700;
}

.modal-header .btn-close {
  --cui-btn-close-color: $greyDark;
  margin-right: 2px !important;
  --cui-btn-close-opacity: 1;
  --cui-btn-close-hover-opacity: 0.8;
}

.footer-modal {
  display: flex;
  justify-content: flex-end;
  .btn {
    margin-left: 4px !important;
    margin-right: 4px;
    width: fit-content;
  }

  .btn.btn-light {
    --cui-btn-border-color: #ebedef;
  }
}

.child-btn-container {
  text-align: end;

  .btn {
    color: #fff;
  }

  .btn-secondary::before {
    content: '+';
    font-size: 20px;
    margin-right: 8px;
  }
}

.form-section-title {
  font-size: 18px;
  font-weight: 700;
}

.modal-remove {
  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-body {
    padding: 0px 8px;
  }

  p {
    text-align: center;
  }

  .modal-footer {
    display: inline-flex;

    .btn:first-of-type {
      flex: 1;
      background-color: transparent;
      --cui-btn-border-color: #929292;
      color: #929292;
    }

    .btn:first-of-type::before {
      content: 'x';
      margin-right: 6px;
      font-size: 20px;
      font-family: 'Inter';
      font-weight: 400;
    }

    .btn:nth-of-type(2) {
      flex: 1;
      background-color: #ff0000;
      --cui-btn-border-color: #ff0000;
    }
  }
}

.modal-confirm {
  .modal-header,
  .modal-footer {
    border: none;
  }

  .modal-body {
    padding: 0px 8px;
  }

  p {
    text-align: center;
  }

  .modal-footer {
    display: inline-flex;

    .btn:first-of-type {
      flex: 1;
      background-color: transparent;
      --cui-btn-border-color: #929292;
      color: #929292;
    }

    // .btn:first-of-type::before {
    //     content: "x";
    //     margin-right: 6px;
    //     font-size: 20px;
    //     font-family: 'Inter';
    //     font-weight: 400;
    // }

    .btn:nth-of-type(2) {
      flex: 1;
      background-color: #5d92fd;
      --cui-btn-border-color: #5d92fd;
    }
  }
}

.form-check-group {
  font-weight: 700;
  color: $greyDark;

  .form-check-input {
    border: 2px solid $grey;
    width: 18px;
    height: 18px;
  }

  .form-check-input:checked {
    border: 2px solid $blue;
    background-color: $blue;
  }
}

.matrix-check-input {
  .form-check-input {
    border: 2px solid $grey;
    width: 18px;
    height: 18px;
  }

  .form-check-input:checked {
    border: 2px solid $blue;
    background-color: $blue;
  }
}

input[type='checkbox'] {
  border: 2px solid $grey !important;
  width: 18px !important;
  height: 18px !important;
}

input[type='checkbox']:checked {
  border: 2px solid $blue !important;
  background-color: $blue !important;
}

button.btn.btn-dark.btn.incident_add_location-btn {
  width: 50px;
  height: 50px !important;
  background-color: $blue;
  border-color: $blue;
  color: #ffff;
}

.gw-form3 {
  label {
    display: inline-flex;
    align-items: center;
    margin-top: 8px;

    input[type='checkbox'] {
      margin-right: 8px;
    }
  }
}

.nav-item {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

/*** form styles ***/

.gw-card-container-no-pointer {
  padding: 10px !important;
}

.gw-card-container {
  padding: 10px !important;
  cursor: pointer !important;
}

.gw-ctc-title {
  color: #2c384a !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 1rem !important;
}

.gw-ctc-subtitle {
  font-size: 12px !important;
  margin-bottom: 0.75rem !important;
  font-weight: 400 !important;
}

.gw-card-img-container img {
  width: 100%;
  height: 250px !important;
  object-fit: contain;
  overflow: hidden;
}

.gw-table-head {
  background: #f9fafb !important;
  text-transform: uppercase !important;
  color: #444444 !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px !important;
  letter-spacing: 0em;
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid #dedede !important;
  padding: 0px 10px !important;
}

.progress-wrapper {
  height: 280px !important;
  overflow: auto !important;
  display: flex !important;
}

.progress_container {
  padding: 10px !important;
}

.progress_infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress_title {
  color: #444444 !important;
  font-size: 12px;
  font-weight: 400;
}

.progress_value {
  color: #444444 !important;
  font-size: 12px;
  opacity: 0.8;
}

.progress_bar {
}

.onlyCardProcessus {
  height: 305px !important;
  overflow: auto !important;
  position: relative !important;
}

.onlyCardProcessus > :first-child {
  transform: scale(0.35) !important;
  -ms-transform-origin-x: left !important;
  -ms-transform-origin-y: top !important;
  -webkit-transform-origin-x: left !important;
  -webkit-transform-origin-y: top !important;
}

.onlyCardProcessus > .carteProcessParent {
  transform: scale(0.4) !important;
  -ms-transform-origin-x: left !important;
  -ms-transform-origin-y: top !important;
  -webkit-transform-origin-x: left !important;
  -webkit-transform-origin-y: top !important;
}

.onlyCardProcessus > .carteProcessChild {
  transform: scale(0.6) !important;
  -ms-transform-origin-x: left !important;
  -ms-transform-origin-y: top !important;
  -webkit-transform-origin-x: left !important;
  -webkit-transform-origin-y: top !important;
}

.onlyCardRisk {
  height: 270px !important;
  overflow: auto !important;
  position: relative !important;
}

.onlyCardRisk .matrix {
  height: 270px !important;
}

.onlyCardRisk > .matrix > :first-child {
  width: max-content !important;
  transform: scale(0.5) !important;
  -ms-transform-origin-x: left !important;
  -ms-transform-origin-y: top !important;
  -webkit-transform-origin-x: left !important;
  -webkit-transform-origin-y: top !important;
}

.gw-card-container .filter-process-risk {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

.gw-card-container .filter-process-risk > .form-select {
  width: 150px !important;
  height: 25px !important;
  padding: 0px 10px !important;
  font-size: 12px !important;
  margin-bottom: 10px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding-right: 30px !important;
}

.footer {
  background-color: #fff !important;
  border-top: 1px solid #dedede !important;
}

.gw-footer {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  height: 25px !important;
}

.gw-footer .copyright {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px !important;
  color: #000000 !important;
  opacity: 40% !important;
  padding: 0px 10px !important;
}

.gw-footer .f_link_utils {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px !important;
}

.gw-footer .f_link_utils .f_link {
  cursor: pointer !important;
  color: #000000 !important;
  opacity: 40% !important;
  font-size: 12px !important;
  padding: 0px 10px !important;
}

@media (max-width: 767px) {
  .push-item-right {
    margin-right: -100px;
  }

  .push-item-left {
    margin-left: -100px;
  }

  .low-push-item-right {
    margin-right: -4px;
  }

  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    --cui-gutter-x: 0rem;
  }

  html:not([dir='rtl']) .ms-2 {
    margin-left: 0rem !important;
  }

  .gw-breadcrumb {
    padding-left: 0px !important;
  }

  .gw-form {
    .row {
      flex-direction: column;
    }

    .row.location-input-row {
      flex-direction: row;
    }
  }

  .gw-react-datatable {
    overflow-x: auto;
    white-space: nowrap;

    .rdt_TableHeadRow {
      .rdt_TableCol:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: #f9fafb;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .rdt_TableCol:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
        background-color: #f9fafb;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .rdt_TableRow {
      .rdt_TableCell:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
      }

      .rdt_TableCell:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
      }
    }
  }



  .custom-responsive {
    tr {
      display: flex;
    }

    th,
    td {
      width: 95px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    th:first-child,
    td:first-child {
      justify-content: flex-start;
      position: sticky;
      left: 0;
      background: white;
      z-index: 1;
      width: 50vw;
      word-wrap: break-word;
      white-space: break-spaces;
    }
  }

  .gw-section-title {
    margin-top: 8px;
  }

  .gw-nav {
    margin-top: 24px !important;
  }
}

.gw-table-cell-listing {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  .listing-value {
    margin-top: 5px !important;
    height: 30px;
    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .other-value {
    font-size: 11px !important;
    color: #5d92fd !important;
    cursor: pointer !important;
    padding: 5px !important;
    font-weight: 700px;
  }
}

.popover-body {
  .gw-ul {
    margin: 0 !important;
    padding-inline-start: 20px !important;
  }
}

.img-spacement {
  padding: 2px !important;
}

.form-error {
  padding: 2px 10px;
}

.cardRisk-scaled {
  transform: scale(0.7) !important;
  -ms-transform-origin-x: left !important;
  -ms-transform-origin-y: top !important;
  -webkit-transform-origin-x: left !important;
  -webkit-transform-origin-y: top !important;
}

.cardRisk-origin {
  padding-top: 20px;
  width: 500px;
  overflow: auto;
  transform: scale(0.7) !important;
  -ms-transform-origin-x: left !important;
  -ms-transform-origin-y: top !important;
  -webkit-transform-origin-x: left !important;
  -webkit-transform-origin-y: top !important;
}
.onlyCardProcessus .empty-data {
  min-width: 100% !important;
}

.alert.alert-access {
  font-size: larger;
}

.form-switch .form-check-input {
  width: 2em !important;
}

.active-label {
  color: $blue !important;
}

.pointer{
  cursor: pointer;
}

.gw-react-datatable.sticky {
  overflow-x: auto;

  .rdt_TableHeadRow {
    .rdt_TableCol:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #f9fafb;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .rdt_TableCol:last-child {
      position: sticky;
      right: 0;
      z-index: 1;
      background-color: #f9fafb;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .rdt_TableRow {
    .rdt_TableCell:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
    }

    .rdt_TableCell:last-child {
      position: sticky;
      right: 0;
      z-index: 1;
    }
  }
}

@media (max-width: 767px) {
  html:not([dir='rtl']) .header-brand {
    margin-right: 1rem !important;
  }

  .wrapper{
    top: 110px !important;
  }

  .sidebar.sidebar-fixed{
    top: 110px !important;
  }

}

@media (max-width: 484px) {
  html:not([dir='rtl']) .header-brand {
    margin-right: 1rem !important;
  }

  .wrapper{
    top: 110px !important;
  }

  .sidebar.sidebar-fixed{
    top: 110px !important;
  }

}


.rdrCalendarWrapper.rdrDateRangeWrapper{
  width: 100% !important;

  .rdrMonth{
    width: auto !important;
  }
}

.alert.empty-data{
  background-color: #FFFFFF !important;
  color: #212529 !important;
}

.react-select-container {
  overflow: visible !important;
}

.react-select__menu {
  z-index: 9999 !important;
}

.applicability-btn{
  padding: 0px !important;
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1920px;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1600px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 1280px;
  }
}

.bold {
  font-weight: bold!important ;
}